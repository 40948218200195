import { Logo, LogoProps, logoPresets } from "utils/components/logo";

import wallofshame from "assets/projects/wallofshame.png";
import doodle2gather from "assets/projects/doodle2gather.png";
import code2gather from "assets/projects/code2gather.png";
import quest from "assets/projects/quest.png";

import app from "app.module.css";
import experience from "./experience.module.css";
import projects from "./projects.module.css";
import useDarkMode from "use-dark-mode";

interface ProjectProps {
  name: string;
  image: string;
  period: string;
  role: string;
  description?: string[];
  contributions?: string[];
  actions?: { name: string; link: string }[];
  skills: { technical: LogoProps[]; design: LogoProps[] };
}

interface ProjectList {
  [project: string]: ProjectProps;
}

const projectList: ProjectList = {
  wallofshame: {
    name: "Wall of Shame",
    image: wallofshame,
    period: "Aug 21 - Nov 21",
    role: "Full Stack Web Developer",
    description: [
      "Wall of Shame is a platform that empowers people to slap procrastination in the face, by utilising the power of friendship and the greatest motivator of all: Public humiliation.",
      "This app was created by a team of 4 for the module CS3216 Software Product Engineering for Digital Markets at NUS and won the third place for the 19th SoC Term Project Showcase (STePS).",
    ],
    contributions: [],
    actions: [
      {
        name: "Try it here!",
        link: "https://www.wallofshame.io",
      },
    ],
    skills: {
      technical: [
        logoPresets.react,
        logoPresets.redux,
        logoPresets.ionic,
        logoPresets.firebase,
        logoPresets.postgres,
      ],
      design: [logoPresets.figma, logoPresets.canva],
    },
  },
  code2gather: {
    name: "Code2Gather",
    image: code2gather,
    period: "Aug 21 - Nov 21",
    role: "Full Stack Web Developer",
    description: [
      "Code2Gather is designed to help programmers to secure internships and jobs by facilitating mock interviews. It allows you to focus on practicing, instead of spending time finding people to practice with.",
    ],
    contributions: [
      "Implemented code editor component for Code2Gather.",
      "Integrated real-time video conferencing.",
    ],
    actions: [],
    skills: {
      technical: [
        logoPresets.react,
        logoPresets.redux,
        logoPresets.typescript,
        logoPresets.firebase,
      ],
      design: [],
    },
  },
  doodle2gather: {
    name: "Doodle2Gather",
    image: doodle2gather,
    period: "Feb 21 - May 21",
    role: "Mobile Developer",
    description: [
      "Doodle2Gather is a collaborative whiteboarding application that aims to be a modern-day replacement for group whiteboard discussions, empowering users to better express themselves on a limitless online canvas.",
      "The project won second place for the 18th SoC Term Project Showcase (STePS).",
    ],
    contributions: [
      "Worked in a team of four to build a real-time collaborative whiteboarding iPad application using Swift and Cocoapods.",
      "Integrated real-time communication functionalities such as chat and video conferencing.",
    ],
    actions: [
      {
        name: "View Doodle2Gather's GitHub page",
        link: "https://github.com/Doodle2Gather/Doodle2Gather",
      },
      {
        name: "Watch the demo video for Doodle2Gather",
        link: "https://www.youtube.com/watch?v=5cVotr76mx4",
      },
    ],
    skills: {
      technical: [
        logoPresets.swift,
        logoPresets.cocoapods,
        logoPresets.firebase,
      ],
      design: [],
    },
  },
  quest: {
    name: "Project Quest",
    image: quest,
    period: "Sep 20 - Sep 21",
    role: "Frontend Developer",
    description: [
      "Quest is a Progressive Web Application (PWA) built for CampusImpact, including a staff management system for the organisation and a gamified learning system for the kids.",
    ],
    contributions: [],
    actions: [],
    skills: {
      technical: [logoPresets.react, logoPresets.redux, logoPresets.typescript],
      design: [logoPresets.figma],
    },
  },
};

const Projects = () => {
  const darkMode = useDarkMode();

  const renderProjectsDisplay = (project: ProjectProps) => {
    const technicalSkills = project.skills.technical;
    const designSkills = project.skills.design;
    const image = (
      <img
        className={projects.projects_image}
        alt={project.image}
        src={project.image}
      />
    );
    return (
      <div
        key={project.name}
        className={
          darkMode.value ? experience.container_dark : experience.container
        }
      >
        <div className={projects.projects_subcontainer}>
          {image}
          <p
            className={experience.title}
            style={{ color: darkMode.value ? "#e7f0fa" : "#2c2e3f" }}
          >
            {project.name}
            <span
              className={experience.subheading}
              style={{ color: darkMode.value ? "#bebebe" : "#666666" }}
            >
              {" "}
              | {project.period}
            </span>
          </p>
          <p
            className={experience.subheading}
            style={{ color: darkMode.value ? "#bebebe" : "#666666" }}
          >
            {project.role}
          </p>
          {project.description?.map((line) => (
            <p
              key={line.substring(0, 20) + "..."}
              className={projects.description}
              style={{ color: darkMode.value ? "#e7f0fa" : "#2c2e3f" }}
            >
              {line}
            </p>
          ))}
          {project.contributions?.length !== undefined &&
            project.contributions?.length > 0 && (
              <ul
                className={projects.contributions}
                style={{ color: darkMode.value ? "#bebebe" : "#666666" }}
              >
                {project.contributions?.map((line) => (
                  <li key={line.substring(0, 20) + "..."}>{line}</li>
                ))}
              </ul>
            )}
          {project.actions?.length !== undefined &&
            project.actions.length > 0 &&
            project.actions.map((action) => (
              <div
                key={action.name}
                className={
                  darkMode.value ? experience.action_dark : experience.action
                }
              >
                <a href={action.link} target='_blank' rel='noreferrer'>
                  <span
                    style={{ color: darkMode.value ? "#e7f0fa" : "#2c2e3f" }}
                  >
                    {action.name}
                  </span>
                </a>
              </div>
            ))}

          <div className={app.container}>
            <div className={projects.skills_display}>
              <p
                className={projects.subheading}
                style={{ color: darkMode.value ? "#bebebe" : "#666666" }}
              >
                Tech Stack
              </p>
              {technicalSkills.map((logoProp) => (
                <Logo key={logoProp.name} {...logoProp} />
              ))}
            </div>
            {designSkills.length > 0 && (
              <div className={projects.skills_display}>
                <p
                  className={projects.subheading}
                  style={{ color: darkMode.value ? "#bebebe" : "#666666" }}
                >
                  Design Stack
                </p>
                {designSkills.map((logoProp) => (
                  <Logo key={logoProp.name} {...logoProp} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div id='projects' className={app.page_block}>
      <h2
        className={app.heading_box}
        style={{ color: darkMode.value ? "#e7f0fa" : "#2c2e3f" }}
      >
        Projects
      </h2>
      {Object.values(projectList).map((project) =>
        renderProjectsDisplay(project)
      )}
    </div>
  );
};

export default Projects;
