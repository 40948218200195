import React from "react";

import Homepage from "components/homepage";
import Skills from "components/skills";
import Experience from "components/experience";
import Projects from "components/projects";
import Contact from "components/contact";

import NavBar from "utils/components/navbar";
import UpButton from "utils/components/upbutton";
import Footer from "utils/components/footer";

import app from "app.module.css";
import useDarkMode from "use-dark-mode";

const MasterPage: React.FC = () => {
  const darkMode = useDarkMode(false);

  return (
    <>
      <NavBar />
      <UpButton />
      <div className={darkMode.value ? app.page_dark : app.page}>
        <Homepage />
        <Skills />
        <Experience />
        <Projects />
        <Contact />
        <Footer />
      </div>
    </>
  );
};

export default MasterPage;
