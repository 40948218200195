import React from "react";

import { scroll } from "utils/smoothscroll";
import useWindowDimensions from "utils/windowdimensions";

import chevron_up from "assets/chevron_up.svg";
import chevron_up_light from "assets/chevron_up_light.svg";
import components from "./components.module.css";
import useDarkMode from "use-dark-mode";

const UpButton: React.FC = () => {
  const darkMode = useDarkMode();
  const { width } = useWindowDimensions();

  return (
    <img
      id='up_button'
      className={`${components.chevron_up_button} ${components.bounce}`}
      onClick={() => scroll()}
      alt='scroll to top'
      src={darkMode.value ? chevron_up_light : chevron_up}
      width={width > 576 ? 56 : 48}
      height={width > 576 ? 56 : 48}
    />
  );
};

export default UpButton;
