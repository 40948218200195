import useWindowDimensions from "utils/windowdimensions";

import app from "app.module.css";
import components from "utils/components/components.module.css";

import linkedIn from "assets/svgs/linkedin.svg";
import linkedInLight from "assets/svgs/linkedin_light.svg";
import github from "assets/logos/github_logo.png";
import githubLight from "assets/logos/github_logo_light.png";
import useDarkMode from "use-dark-mode";

const Footer = () => {
  const darkMode = useDarkMode();

  const openLink = (url: string): void => window.open(url, "_blank")?.focus();

  const webFooter = (
    <>
      <div
        className={
          darkMode.value
            ? components.footer_background_dark
            : components.footer_background
        }
      />
      <div className={components.footer_content}>
        <div className={components.footer_content_left}>
          <span
            className={components.footer_heading}
            style={{ color: darkMode.value ? "#e7f0fa" : "#2c2e3f" }}
          >
            Wang Luo
          </span>
        </div>
        {/* 
      Removed temporarily for now
      <div className={components.footer_content_center}>
        <div className={components.footer_zoojaicon}>
          <ZoojaIcon icon="c" />
        </div>
        <span className={components.footer_text}>Have a great day!</span>
      </div> 
      */}
        <div className={components.buttons}>
          <img
            alt='github'
            className={app.info_btn}
            src={darkMode.value ? githubLight : github}
            width={48}
            height={48}
            onClick={() => openLink("https://github.com/Asthenosphere")}
          />
          <img
            alt='linkedin'
            className={app.info_btn}
            src={darkMode.value ? linkedInLight : linkedIn}
            width={48}
            height={48}
            onClick={() =>
              openLink("https://www.linkedin.com/in/wang-luo-98016a191/")
            }
          />
        </div>
      </div>
    </>
  );

  const mobileFooter = (
    <>
      <div
        className={
          darkMode.value
            ? components.footer_background_dark
            : components.footer_background
        }
      />

      <div className={components.footer_content}>
        <span className={components.footer_heading}>Wang Luo</span>
        <div className={components.buttons}>
          <img
            alt='github'
            className={app.info_btn}
            src={darkMode.value ? githubLight : github}
            width={48}
            height={48}
            onClick={() => openLink("https://github.com/Asthenosphere")}
          />
          <img
            alt='linkedin'
            className={app.info_btn}
            src={darkMode.value ? linkedInLight : linkedIn}
            width={48}
            height={48}
            onClick={() =>
              openLink("https://www.linkedin.com/in/wang-luo-98016a191/")
            }
          />
        </div>
      </div>
    </>
  );

  const { width } = useWindowDimensions();
  return (
    <div className={components.footer}>
      {width > 768 ? webFooter : mobileFooter}
    </div>
  );
};

export default Footer;
