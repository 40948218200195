import React from "react";

import app from "app.module.css";
import homepage from "./homepage.module.css";
import useWindowDimensions from "utils/windowdimensions";
import useDarkMode from "use-dark-mode";

const Homepage: React.FC = () => {
  const darkMode = useDarkMode();
  const { width } = useWindowDimensions();

  const renderHeading = () => (
    <div className={homepage.heading_section}>
      <span
        className={homepage.heading}
        style={{ color: darkMode.value ? "#e7f0fa" : "#2c2e3f" }}
      >
        Wang Luo
      </span>
    </div>
  );

  return (
    <div id='homepage' className={app.page_block}>
      {width > 576 && <div className={app.blank_space} />}
      <div>
        <p
          className={homepage.intro_message_top}
          style={{ color: darkMode.value ? "#e7f0fa" : "#2c2e3f" }}
        >
          Hello there, I'm
        </p>
      </div>
      {renderHeading()}
      <div className={homepage.introduction}>
        <p
          className={homepage.intro_message}
          style={{ color: darkMode.value ? "#e7f0fa" : "#2c2e3f" }}
        >
          I enjoy turning creative ideas and beautiful designs into reality ;)
        </p>
        <p
          className={homepage.intro_message}
          style={{ color: darkMode.value ? "#e7f0fa" : "#2c2e3f" }}
        >
          Currently a final year Computer Science undergraduate at the National
          University of Singapore.
        </p>
      </div>
    </div>
  );
};

export default Homepage;
