import { Logo, LogoProps, logoPresets } from "utils/components/logo";

import app from "app.module.css";
import experience from "./experience.module.css";
import projects from "./projects.module.css";
import useDarkMode from "use-dark-mode";

interface ExperienceProps {
  company: string;
  site: string;
  period: string;
  role: string;
  additional_notes?: string;
  description: string[];
  actions?: { name: string; link: string }[];
  skills: { technical: LogoProps[]; design: LogoProps[] };
}

interface ExperienceList {
  [company: string]: ExperienceProps;
}

const experienceList: ExperienceList = {
  tiktok: {
    company: "TikTok",
    site: "https://www.tiktok.com",
    period: "May 22 - Aug 22",
    role: "iOS Developer",
    description: [
      "Took over and led the development of the redesigned chat page that has reached over 180 million users.",
      "Developed the privacy settings for the message read status feature.",
      "Implemented various other features and fixes in the TikTok iOS App.",
    ],
    skills: {
      technical: [
        logoPresets.objectivec,
        logoPresets.cocoapods,
        logoPresets.xcode,
        logoPresets.git,
        logoPresets.jira,
      ],
      design: [],
    },
  },
  sea: {
    company: "SeaTalk, Sea Group",
    site: "https://www.sea.com/home",
    period: "May 21 - Aug 21",
    role: "iOS Engineer",
    description: [
      "Spearheaded the design and implementation of a foundational iOS SDK for language localization that is integrated with numerous internal Apps across Sea.",
      "Reduced the loading time of large albums in the SeaTalk iOS App from over 5 seconds to under 1 second, thereby greatly improved the user experience when selecting media to send.",
      "Implemented various other features and fixes across SeaTalk, such as support for WebP stickers and real-time sticker syncing.",
    ],
    skills: {
      technical: [
        logoPresets.swift,
        logoPresets.reactiveswift,
        logoPresets.cocoapods,
        logoPresets.swiftPM,
        logoPresets.git,
        logoPresets.jira,
      ],
      design: [],
    },
  },
  cvwo: {
    company: "Computing for Voluntary Welfare Organisations (CVWO)",
    site: "https://www.comp.nus.edu.sg/~vwo/",
    period: "May 20 – Aug 20",
    role: "Full Stack Web Developer",
    description: [
      "Built the client-facing application for the Lions Befrienders Service Association (LBSA) using React, Redux and Ionic that streamlines the workflow of volunteers, allowing LBSA to scale up their operations.",
      "Developed a new management system using Rails for LBSA staff to record case histories of their senior clients.",
      "Developed and deployed an Express API server that manages the electoral division data for LBSA clients.",
    ],
    skills: {
      technical: [
        logoPresets.react,
        logoPresets.redux,
        logoPresets.typescript,
        logoPresets.css,
        logoPresets.html,
        logoPresets.ruby,
        logoPresets.rails,
        logoPresets.git,
      ],
      design: [],
    },
  },
  sourceacademy: {
    company: "Source Academy, NUS",
    site: "https://sourceacademy.nus.edu.sg",
    period: "Jan 20 – May 20",
    role: "Frontend Web Developer",
    description: [
      "Developed new features such as new game effects and patched flaws for the front-end game component of Source Academy.",
      "Improved the communication of user data between the front-end and back-end via new API endpoints.",
    ],
    skills: {
      technical: [
        logoPresets.react,
        logoPresets.redux,
        logoPresets.typescript,
        logoPresets.css,
        logoPresets.html,
        logoPresets.git,
      ],
      design: [],
    },
  },
  cs3217: {
    company: "Nationcal University of Singapore",
    site: "https://www.nus.edu.sg",
    period: "Jan 22 - Apr 22",
    role: "CS3217 Teaching Assistant",
    description: [
      "Mentoring 17 students over different milestones across their individual projects.",
      "Managing administrative work such as peer review allocations and submission tracking.",
      "Constantly providing feedback to students on their architecture design and code quality for their individual projects.",
    ],
    skills: {
      technical: [
        logoPresets.swift,
        logoPresets.python,
        logoPresets.git,
        logoPresets.github,
      ],
      design: [],
    },
  },
};

const Experience = () => {
  const darkMode = useDarkMode();

  const renderExperienceDisplay = (company: ExperienceProps) => {
    const technicalSkills = company.skills.technical;
    const designSkills = company.skills.design;

    return (
      <div
        key={company.company}
        className={
          darkMode.value ? experience.container_dark : experience.container
        }
      >
        <div className={projects.projects_subcontainer}>
          <p
            className={experience.title}
            style={{ color: darkMode.value ? "#e7f0fa" : "#2c2e3f" }}
          >
            {company.role}
            <span
              className={experience.subheading}
              style={{ color: darkMode.value ? "#bebebe" : "#666666" }}
            >
              {" "}
              | {company.period}
            </span>
          </p>
          <p
            className={experience.subheading}
            style={{ color: darkMode.value ? "#bebebe" : "#666666" }}
          >
            {company.company}
          </p>
          <ul>
            {company.description.map((line) => (
              <li
                key={line.substring(0, 10) + "..."}
                className={experience.description}
                style={{ color: darkMode.value ? "#e7f0fa" : "#2c2e3f" }}
              >
                {line}
              </li>
            ))}
          </ul>

          {company.actions?.length &&
            company.actions.map((action) => (
              <div key={action.name} className={experience.action}>
                <a href={action.link} target='_blank' rel='noreferrer'>
                  <img
                    alt='link icon'
                    src='https://img.icons8.com/material-outlined/50/666666/link--v1.png'
                  />
                  {action.name}
                </a>
              </div>
            ))}

          <div className={app.container}>
            <div className={projects.skills_display}>
              <p
                className={projects.subheading}
                style={{ color: darkMode.value ? "#bebebe" : "#666666" }}
              >
                Tech Stack
              </p>
              {technicalSkills.map((logoProp) => (
                <Logo key={logoProp.name} {...logoProp} />
              ))}
            </div>
            {designSkills.length > 0 && (
              <div className={projects.skills_display}>
                <p
                  className={projects.subheading}
                  style={{ color: darkMode.value ? "#bebebe" : "#666666" }}
                >
                  Design Stack
                </p>
                {designSkills.map((logoProp) => (
                  <Logo key={logoProp.name} {...logoProp} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div id='experience' className={app.page_block}>
      <h2
        className={app.heading_box}
        style={{ color: darkMode.value ? "#e7f0fa" : "#2c2e3f" }}
      >
        Experience
      </h2>
      {Object.values(experienceList).map((experience) =>
        renderExperienceDisplay(experience)
      )}
    </div>
  );
};

export default Experience;
