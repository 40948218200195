import React, { useState } from "react";
import { Link } from "react-router-dom";

import { DarkModeSwitch } from "react-toggle-dark-mode";
import Hamburger from "hamburger-react";
import useDarkMode from "use-dark-mode";

import useWindowDimensions from "utils/windowdimensions";
import logo from "assets/avatar.png";

import components from "./components.module.css";

const NavBar: React.FC = () => {
  const darkMode = useDarkMode(false);
  const { width } = useWindowDimensions();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const scrollTo = (id: string) => {
    const element = document.getElementById(id) as HTMLDivElement;
    element.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <>
      <header
        id='navbar'
        className={components.navbar}
        style={{ backgroundColor: darkMode.value ? "#2c2e3f" : "#e7f0fa" }}
      >
        <img src={logo} alt='homepage button' width={48} height={48} />

        {width > 992 ? (
          <div className={components.links}>
            <Link
              className={
                darkMode.value ? components.link_dark : components.link
              }
              to='#'
              onClick={() => scrollTo("skills")}
            >
              Skills
            </Link>
            <Link
              className={
                darkMode.value ? components.link_dark : components.link
              }
              to='#'
              onClick={() => scrollTo("experience")}
            >
              Experience
            </Link>
            <Link
              className={
                darkMode.value ? components.link_dark : components.link
              }
              to='#'
              onClick={() => scrollTo("projects")}
            >
              Projects
            </Link>
            <Link
              className={
                darkMode.value ? components.link_dark : components.link
              }
              to='#'
              onClick={() => scrollTo("contact")}
            >
              Contact
            </Link>
            <DarkModeSwitch
              checked={darkMode.value}
              onChange={darkMode.toggle}
              size={24}
              style={{ marginLeft: "1rem" }}
              sunColor='#fe7549'
              moonColor='#7dd7e1'
            />
          </div>
        ) : (
          <div className={components.menu}>
            <Hamburger
              toggled={isOpen}
              toggle={handleOpen}
              size={24}
              color={darkMode.value ? "#e7f0fa" : "#2c2e3f"}
            />
            <DarkModeSwitch
              checked={darkMode.value}
              onChange={darkMode.toggle}
              size={24}
              className={components.menu_toggle}
              sunColor='#fe7549'
              moonColor='#7dd7e1'
            />
          </div>
        )}
      </header>
      {isOpen && width <= 992 && (
        <div
          className={components.navbar_open}
          style={{
            backgroundColor: darkMode.value ? "#2c2e3f" : "#e7f0fa",
          }}
        >
          <ul className={components.menu_list}>
            <li className={components.menu_item}>
              <Link
                className={
                  darkMode.value
                    ? components.menu_link_dark
                    : components.menu_link
                }
                to='#'
                onClick={() => scrollTo("skills")}
              >
                Skills
              </Link>
            </li>
            <li className={components.menu_item}>
              <Link
                className={
                  darkMode.value
                    ? components.menu_link_dark
                    : components.menu_link
                }
                to='#'
                onClick={() => scrollTo("experience")}
              >
                Experience
              </Link>
            </li>
            <li className={components.menu_item}>
              <Link
                className={
                  darkMode.value
                    ? components.menu_link_dark
                    : components.menu_link
                }
                to='#'
                onClick={() => scrollTo("projects")}
              >
                Projects
              </Link>
            </li>
            <li className={components.menu_item}>
              <Link
                className={
                  darkMode.value
                    ? components.menu_link_dark
                    : components.menu_link
                }
                to='#'
                onClick={() => scrollTo("contact")}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default NavBar;
