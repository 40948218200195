import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";

import MasterPage from "pages/masterpage";

const App: React.FC = () => {
  return (
    <div>
      <HashRouter hashType='noslash'>
        <Switch>
          <Route component={MasterPage} />
        </Switch>
      </HashRouter>
    </div>
  );
};

export default App;
