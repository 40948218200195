import React from "react";

import useWindowDimensions from "utils/windowdimensions";

import rails_logo from "assets/svgs/rails.svg";
import cocoapods_logo from "assets/svgs/cocoapods.svg";
import swiftPM_logo from "assets/logos/swiftPM.png";
import reactiveswift_logo from "assets/logos/reactiveswift.png";
import ionic_logo from "assets/logos/ionic.png";
import canva_logo from "assets/logos/canva_logo.png";
import objectivec_logo from "assets/logos/objectivec_logo.png";

import components from "./components.module.css";
import useDarkMode from "use-dark-mode";

export interface LogoProps {
  image: string;
  name: string;
  size?: number;
}

export const Logo: React.FC<LogoProps> = (props: LogoProps) => {
  const darkMode = useDarkMode();
  const { width } = useWindowDimensions();

  const { image, name, size } = props;

  return (
    <div className={components.logo}>
      <img
        alt={`${name} logo`}
        className={components.logo_image}
        style={{
          height: `${width > 576 ? size : size! - 10}px`,
          marginTop: `${(60 - size!) * 0.5}px`,
        }}
        src={image}
      />
      {width > 576 && (
        <div
          className={
            darkMode.value ? components.logo_box_dark : components.logo_box
          }
        />
      )}

      <span
        className={
          darkMode.value ? components.logo_name_dark : components.logo_name
        }
      >
        {name}
      </span>
    </div>
  );
};

export const logoPresets: { [name: string]: LogoProps } = {
  javascript: {
    image: "https://img.icons8.com/color/240/000000/javascript--v1.png",
    name: "Javascript",
  },
  typescript: {
    image: "https://img.icons8.com/color/240/000000/typescript.png",
    name: "Typescript",
  },
  swift: {
    image: "https://img.icons8.com/color/240/000000/swift.png",
    name: "Swift",
  },
  objectivec: {
    image: objectivec_logo,
    name: "Objective-C",
  },
  java: {
    image:
      "https://img.icons8.com/color/240/000000/java-coffee-cup-logo--v1.png",
    name: "Java",
  },
  react: {
    image: "https://img.icons8.com/color/240/000000/react-native.png",
    name: "ReactJS",
    size: 40,
  },
  redux: {
    image: "https://img.icons8.com/color/240/000000/redux.png",
    name: "Redux",
    size: 40,
  },
  html5: {
    image: "https://img.icons8.com/color/240/000000/html-5--v1.png",
    name: "HTML",
  },
  css: {
    image: "https://img.icons8.com/color/240/000000/css3.png",
    name: "CSS",
  },
  csharp: {
    image: "https://img.icons8.com/color/240/000000/c-sharp-logo.png",
    name: "C#",
  },
  html: {
    image: "https://img.icons8.com/color/240/000000/html-5--v1.png",
    name: "HTML 5",
    size: 46,
  },
  springboot: {
    image: "https://img.icons8.com/color/240/000000/spring-logo.png",
    name: "SpringBoot",
  },
  dart: {
    image: "https://img.icons8.com/color/240/000000/dart.png",
    name: "Dart",
  },
  flutter: {
    image: "https://img.icons8.com/color/240/000000/flutter.png",
    name: "Flutter",
  },
  firebase: {
    image: "https://img.icons8.com/color/240/000000/firebase.png",
    name: "Firebase",
  },
  ionic: {
    image: ionic_logo,
    name: "Ionic",
    size: 38,
  },
  docker: {
    image: "https://img.icons8.com/color/240/000000/docker.png",
    name: "Docker",
  },
  xcode: {
    image: "https://img.icons8.com/color/240/000000/xcode.png",
    name: "Xcode",
  },
  cocoapods: {
    image: cocoapods_logo,
    name: "Cocoapods",
    size: 36,
  },
  reactiveswift: {
    image: reactiveswift_logo,
    name: "ReactiveSwift",
    size: 36,
  },
  swiftPM: {
    image: swiftPM_logo,
    name: "Swift Package Manager",
    size: 36,
  },
  vscode: {
    image:
      "https://img.icons8.com/color/240/000000/visual-studio-code-2019.png",
    name: "Visual Studio Code",
  },
  intellij: {
    image: "https://img.icons8.com/color/240/000000/intellij-idea.png",
    name: "IntelliJ IDEA",
  },
  git: {
    image: "https://img.icons8.com/color/240/000000/git.png",
    name: "Git",
  },
  jira: {
    image: "https://img.icons8.com/color/240/000000/jira.png",
    name: "JIRA",
    size: 40,
  },
  rails: {
    image: rails_logo,
    name: "Rails",
    size: 28,
  },
  ruby: {
    image:
      "https://img.icons8.com/color/240/000000/ruby-programming-language.png",
    name: "Ruby",
  },
  python: {
    image: "https://img.icons8.com/color/240/000000/python--v1.png",
    name: "Python",
  },
  c: {
    image: "https://img.icons8.com/color/240/000000/c-programming.png",
    name: "C",
  },
  cpp: {
    image: "https://img.icons8.com/color/240/000000/c-plus-plus-logo.png",
    name: "C++",
  },
  postgres: {
    image: "https://img.icons8.com/color/240/000000/postgreesql.png",
    name: "PostgreSQL",
  },
  figma: {
    image: "https://img.icons8.com/color/240/000000/figma--v1.png",
    name: "Figma",
    size: 42,
  },
  canva: {
    image: canva_logo,
    name: "Canva",
    size: 42,
  },
  illustrator: {
    image: "https://img.icons8.com/color/240/000000/adobe-illustrator--v1.png",
    name: "Adobe Illustrator",
  },
  xd: {
    image: "https://img.icons8.com/color/240/000000/adobe-xd--v1.png",
    name: "Adobe XD",
  },
  photoshop: {
    image: "https://img.icons8.com/color/240/000000/adobe-photoshop--v1.png",
    name: "Adobe Photoshop",
  },
  premierepro: {
    image: "https://img.icons8.com/color/48/000000/adobe-premiere-pro--v1.png",
    name: "Adobe Premiere Pro",
  },
  procreate: {
    image: "https://img.icons8.com/color/240/000000/procreate.png",
    name: "Procreate",
  },
  github: {
    image: "https://img.icons8.com/color/240/000000/github--v1.png",
    name: "Github",
  },
  wordpress: {
    image: "https://img.icons8.com/color/48/000000/wordpress.png",
    name: "Wordpress",
  },
};
