import { Logo, LogoProps, logoPresets } from "utils/components/logo";

import app from "app.module.css";
import skills from "./skills.module.css";
import useDarkMode from "use-dark-mode";

interface SkillList {
  [skill: string]: {
    confident: LogoProps[];
    tried: LogoProps[];
  };
}

export const skillList: SkillList = {
  programming: {
    confident: [
      logoPresets.javascript,
      logoPresets.typescript,
      logoPresets.swift,
      logoPresets.java,
      logoPresets.react,
      logoPresets.redux,
      logoPresets.html5,
      logoPresets.css,
      logoPresets.ionic,
    ],
    tried: [
      logoPresets.python,
      logoPresets.ruby,
      logoPresets.c,
      logoPresets.cpp,
      logoPresets.rails,
      logoPresets.postgres,
    ],
  },
  tools: {
    confident: [
      logoPresets.docker,
      logoPresets.xcode,
      logoPresets.vscode,
      logoPresets.intellij,
      logoPresets.git,
      logoPresets.jira,
    ],
    tried: [],
  },
  design: {
    confident: [logoPresets.figma, logoPresets.canva],
    tried: [logoPresets.procreate, logoPresets.xd],
  },
};

const Skills = () => {
  const darkMode = useDarkMode();

  const renderSkillDisplay = (skill: string) => {
    return (
      <>
        <p>
          <strong style={{ color: darkMode.value ? "#e7f0fa" : "#2c2e3f" }}>
            {skill.charAt(0).toUpperCase() + skill.slice(1)}
          </strong>
        </p>
        <div className={app.container}>
          <div className={skills.skills_display}>
            <p
              className={skills.subheading}
              style={{ color: darkMode.value ? "#bebebe" : "#666666" }}
            >
              I am not bad with
            </p>
            {skillList[skill].confident.map((logoProp) => (
              <Logo key={logoProp.name} {...logoProp} />
            ))}
          </div>
          {skillList[skill].tried.length > 0 && (
            <div className={skills.skills_display}>
              <p
                className={skills.subheading}
                style={{ color: darkMode.value ? "#bebebe" : "#666666" }}
              >
                I have played around with
              </p>
              {skillList[skill].tried.map((logoProp) => (
                <Logo key={logoProp.name} {...logoProp} />
              ))}
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div id='skills' className={app.page_block}>
      <h2
        className={app.heading_box}
        style={{ color: darkMode.value ? "#e7f0fa" : "#2c2e3f" }}
      >
        Skills
      </h2>
      <div>
        {renderSkillDisplay("programming")}
        {renderSkillDisplay("tools")}
        {renderSkillDisplay("design")}
      </div>
    </div>
  );
};

export default Skills;
